<template>
	<div>
		<TableList :rowKey="'id'" :form_data_seo.sync="form_data_seo" :columns="columns"
			:get_table_list="this.get_table_list" :rowSelection="rowSelection"
			:slot_table_list="['operation','status','goods_logo','goods_put','factory_info']" ref="list">
			<template slot="left_btn">
				<a-button class="float_left" @click="Synchronize()" type="primary">同步商品</a-button>
				<a-button type="primary" style="margin-left: 10px;" @click="setFactory" :disabled="!hasSelected">批量更改工厂
				</a-button>
			</template>
			<template slot="operation" slot-scope="data">
				<span>
					<a @click="see(data.record)">查看</a>
				</span>
			</template>

			<template slot="factory_info" slot-scope="data">
				<span>
					<a @click="see2(data.record)">{{data.record.factory_info.length}}</a>
				</span>
			</template>
		</TableList>
		<a-modal v-model="visible" title="货物详情" @ok="visible=false">
			<a-descriptions bordered>
				<a-descriptions-item :span="3" label="货物编码">
					{{see_info.goods_id}}
				</a-descriptions-item>
				<a-descriptions-item :span="3" label="货物名称">
					{{see_info.goods_name}}
				</a-descriptions-item>
				<a-descriptions-item :span="3" label="品牌名称">
					{{see_info.brand_name}}
				</a-descriptions-item>
				<a-descriptions-item :span="3" label="品牌编码">
					{{see_info.brand_id}}
				</a-descriptions-item>
				<a-descriptions-item :span="3" label="货物系列名称">
					{{see_info.series_name}}
				</a-descriptions-item>
				<a-descriptions-item :span="3" label="货物系列编号">
					{{see_info.series_id}}
				</a-descriptions-item>
				<a-descriptions-item :span="3" label="货物品相名称">
					{{see_info.merchandise_name}}
				</a-descriptions-item>
				<a-descriptions-item :span="3" label="货物所属品号">
					{{see_info.merchandise_id}}
				</a-descriptions-item>
				<a-descriptions-item :span="3" label="货物生产工厂">
					<p v-for="(item,index) in see_info.factory_info" :key="index">
						{{item.factory_name}}
					</p>
				</a-descriptions-item>
			</a-descriptions>
		</a-modal>


		<a-modal v-model="visibleFa" title="商品工厂列表" @ok="saveFa">
			<a-button type="primary" style="margin-left: 10px;" @click="setFactory">添加工厂
			</a-button>
			<a-table :dataSource="fa_info" :columns="columnsFaList">
				<template slot="operation" slot-scope="text,record,index">
					<span>
						<a @click="del(index)">删除</a>
					</span>
				</template>

			</a-table>

		</a-modal>
		<!-- 选择工厂 -->
		<SelectFactory @submit="submit" :visible.sync="visibleFactory"></SelectFactory>

		<SelectFactory2 @submit="submit2" :visible.sync="visibleFactory2"></SelectFactory2>
	</div>
</template>

<script>
	import TableList from "@/components/TableList";
	import {
		getDrpGoodsDetail,
		getDrpGoodsList,
		syncDrpGoodsInfo,
		changeFactoryGoods
	} from "@/api/goods";
	import SelectFactory from '@/components/select_factory.vue'
	import SelectFactory2 from '@/components/select_factory.vue'
	let columnsFaList = [{
		title: "工厂名称",
		dataIndex: "factory_name",
	}, {
		title: "工厂编号",
		dataIndex: "factory_id",
	}, {
		title: "操作",
		dataIndex: "operation",
		scopedSlots: {
			customRender: "operation"
		}
	}, ]
	let columns = [{
			title: "货物编码",
			dataIndex: "goods_id",
		},
		{
			title: "货物名称",
			dataIndex: "goods_name",

		},

		{
			title: "品牌名称",
			dataIndex: "brand_name",
		},
		{
			title: "品牌编码",
			dataIndex: "brand_id",
		},
		{
			title: "货物系列名称",
			dataIndex: "series_name",
		},
		{
			title: "货物系列编号",
			dataIndex: "series_id",
		},
		{
			title: "货物品相名称",
			dataIndex: "merchandise_name",

		},
		{
			title: "货物所属品号",
			dataIndex: "merchandise_id",
		},
		{
			title: "工厂数量",
			dataIndex: "factory_info",
			scopedSlots: {
				customRender: "factory_info"
			}
		},

		{
			title: "操作",
			scopedSlots: {
				customRender: "operation"
			}
		},
	];

	export default {
		name: "gpoodIndex",
		components: {
			TableList,
			SelectFactory,
			SelectFactory2
		},
		data() {
			return {
				fa_info: [],
				visibleFa: false,
				get_table_list: getDrpGoodsList,
				see_info: {},
				columns,
				goods_ids: '',
				columnsFaList,
				visible: false,
				form_data_seo: {
					list: [{
						type: "text",
						name: "keyword",
						title: "关键词",
						placeholder: '请输入商品名称',
						options: {

						}
					}, ],
					...this.$config.form_data_seo
				},
				selectedRowKeys: [], // table中选中的id
				selectedRows: [], // table中选中的数据
				visibleFactory: false,
				visibleFactory2: false,
			};
		},
		async created() {},
		computed: {

			hasSelected() {
				return this.selectedRowKeys.length > 0;
			},
			rowSelection() {
				const {
					selectedRowKeys
				} = this;
				return {
					selectedRowKeys,
					onChange: (selectedRowKeys, selectedRows) => {
						// 由于selectedRows在翻页后不会保留之前选中的数据，所以需要在此处进行额外处理
						// 在这本次selectedRows之前已经选中了的用户
						let prevSelectedRows = this.selectedRows;
						// 将变化前的selectedRows与现在的selectedRows进行结合
						let selectedCustomerInfo = [...prevSelectedRows, ...selectedRows];
						// 由于可能会出现重复，所以进行去重处理
						let userArr = [];
						let flag;
						for (let i in selectedCustomerInfo) {
							flag = true;
							for (let j in userArr) {
								if (userArr[j].id == selectedCustomerInfo[i].id) {
									flag = false;
								}
							}
							if (flag) {
								userArr.push(selectedCustomerInfo[i]);
							}
						}
						this.selectedRows = userArr;

						this.selectedRowKeys = selectedRowKeys;
					},
					hideDefaultSelections: true,
				};
			}
		},
		methods: {
			del(index) {
				this.fa_info.splice(index, 1)
			},
			// 同步商品 syncDrpGoodsInfo
			Synchronize() {
				syncDrpGoodsInfo({
					data: {},
					info: true
				}).then(res => {
					this.$refs.list.get_list();
				})
			},
			see(data) {
				this.see_info = data
				this.visible = true
			},
			see2(data) {
				this.fa_info = data.factory_info
				this.goods_ids = data.id
				this.visibleFa = true
			},
			// 批量更改工厂
			setFactory() {
				this.visibleFactory = true
			},
			// 批量更改工厂
			setFactory2() {
				this.visibleFactory2 = true
			},
			saveFa() {
				changeFactoryGoods({
					data: {
						ids: this.goods_ids,
						factory_ids: this.fa_info.map(item => {
							return item.factory_id
						}).join(',')
					},
					info: true
				}).then(() => {
					this.$refs.list.get_list();
					this.fa_info = []
					this.goods_ids = ''
					this.visibleFa = false

				})
			},
			submit2({
				data
			}) {

				data.forEach(item => {
					this.fa_info.push({
						factory_name: item.factory_name,
						factory_id: item.id,
					})
				})
				this.visibleFactory2 = false

			},
			submit({
				data
			}) {

				if (this.visibleFa) {
					data.forEach(item => {
						this.fa_info.push({
							factory_name: item.factory_name,
							factory_id: item.id,
						})
					})
					this.visibleFactory2 = false
				} else {
					changeFactoryGoods({
						data: {
							ids: this.selectedRowKeys.join(','),
							factory_ids: data.map(item => {
								return item.id
							}).join(',')
						},
						info: true,
						loading: true
					}).then(() => {
						this.$refs.list.get_list();
						this.selectedRowKeys = []
						this.visibleFactory = false
					})
				}

			},
		}
	};
</script>

<style lang="less">
	@import url("../../assets/less/app.less");
</style>